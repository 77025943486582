<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Levels</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active">Levels</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link v-can="'add_levels'" :to="{ name: 'Levels-Create' }" class="btn btn-primary">Add
              New</router-link>
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <!-- form will be here -->
              </div>
            </div>

            <div class="table-responsive">
              <EasyDataTable :loading="loading" :rows-per-page="10" :rows-items="[25, 50, 100]" :headers="headers"
                :items="levels" alternating buttons-pagination>

                <template #item-classes="{ classes }">
                  <div>
                    <span class="gray-tag" v-for="single_class in classes" :key="single_class.id">
                      {{ single_class.name }}:
                      {{ single_class.capacity }}
                    </span>
                  </div>
                </template>
                <template #item-actions="{ id }">
                  <div>
                    <router-link v-can="'edit_levels'" :to="{
                      name: 'Levels-Edit',
                      params: { id: id },
                    }" title="Edit" class="mr-4"><i class="fa fa-pencil color-muted"></i>
                    </router-link>
                    <a v-can="'delete_levels'" @click.prevent="deleteData(id)" href="javascript:void()" title="Delete"><i
                        class="fa fa-close color-danger"></i></a>
                  </div>
                </template>

              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useLevels from "@/composables/levels";
import { inject, onBeforeMount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
export default {
  components: {
    EasyDataTable,
  },
  setup() {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const loading = ref(true);

    const { levels, getLevels, deleteLevel } = useLevels();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_levels")) {
        router.push({
          name: "401",
        });
      }
    });

    onMounted(() => {
      getLevels().then(() => {
        console.log('D', levels.value);
        loading.value = false;
      });
    });

    const deleteData = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteLevel(id).then(() => {
              swal.fire("Deleted!", "The data has been deleted successfully.", "success");
              getLevels();
            });
          }
        });
    };

    const headers = ref([
      { text: "ID", value: "id" },
      { text: "Name", value: "value", sortable: true },
      { text: "Order", value: "level_order", sortable: true },
      { text: "Classes", value: "classes", sortable: true },
      { text: "Created At", value: "created_at", sortable: true },
      { text: "Updated At", value: "updated_at", sortable: true },
      { text: "Actions", value: "actions", sortable: true },
    ]);

    return {
      headers,
      loading,
      levels,
      deleteData,
    };
  },
};
</script>


<style>
.vue3-easy-data-table__header tr th .header {
  position: unset !important;
  color: black !important;
  text-align: left !important;
  background-color: #fafafa !important;
  padding-left: 1px !important;
  height: 2rem !important;
}
</style>
